import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ApplicationInsightsErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any): void {
        console.error(error);

        if (!environment.features.applicationInsights.isUsed) {
            return;
        }

        this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
    }
}
